<template>
    <div id="build-details-form">
        <div class="top">
            <div class="top__left">
                <h2 class="mb-3">
                    Setup & Connect Google Assets
                </h2>
                <p class="desc">
                    Looking good! In order to run Google's Vehicle Listing Ads program you'll need to, first,
                    setup all the necessary asset dependencies which they require to run the program.
                    On the next step we'll walk you through the process of setting up the ads as well! 🙌
                </p>
            </div>
            <div class="top__right">
                <div>
                    <action-button
                        class="icon"
                        width="45"
                        height="45"
                        href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-google-vehicle-listing-ads-vlas"
                        target="_blank"
                        icon="book"
                        :color="colors.blue"
                        @click.prevent="">
                        <div class="dark-word">
                            READ OUR GUIDE
                        </div>
                    </action-button>
                </div>
            </div>
        </div>

        <v-form
            ref="form"
            v-model="formValid">
            <div class="build-details">
                <loader v-if="loading" />
                <div v-else>
                    <div
                        v-if="errorMessage"
                        class="err-message">
                        {{ errorMessage }}
                    </div>

                    <!-- AD ACCOUNT -->
                    <div>
                        <h2>Ad Account</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Use the guide to the right to walk through the process of setting up a Google
                                    ads account and enter the account ID once you're finished
                                </p>
                            </div>
                            <div
                                v-if="!adAccountComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="accountId"
                                    label="AD ACCOUNT ID (OPTIONAL)"
                                    placeholder="Enter Account ID..."
                                    class="styled-field" />
                                <div class="small-desc">
                                    Entering the Ad Account ID now is optional but will make it easier
                                    to activate future Google features if you have it now
                                </div>
                            </div>
                            <div v-if="adAccountComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!adAccountComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-google-vehicle-listing-ads-vlas#create-google-ads-account"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- STORE CODE -->
                    <div class="mt-4">
                        <h2>Profile Store Code</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Google Merchant center requires the feed to match the "Store Code" configured on your Google Business Profile.
                                    If you don't already have a store code you can use our tool to suggest one but you can choose any code you want!
                                </p>
                            </div>
                            <div
                                v-if="!storeCodeComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="storeCode"
                                    :rules="requiredRules"
                                    label="STORE CODE (REQUIRED)"
                                    placeholder="Enter Store Code..."
                                    class="styled-field" />
                                <div>
                                    <a
                                        v-if="!generatedCode"
                                        class="generate-code"
                                        href="#"
                                        @click.prevent="generateStoreCode">Generate random code</a>
                                    <button
                                        v-else
                                        v-clipboard:copy="generatedCode"
                                        v-clipboard:success="onCopySuccess">
                                        <a
                                            class="generate-code"
                                            href="#"
                                            @click.prevent>{{ generatedCode }}</a>
                                    </button>
                                    <span
                                        v-if="!generatedCode"
                                        class="dark-word"> (optional)</span>
                                    <button
                                        v-if="generatedCode"
                                        v-clipboard:copy="generatedCode"
                                        v-clipboard:success="onCopySuccess">
                                        <action-button
                                            class="mt-1 ml-1"
                                            width="14"
                                            height="14"
                                            icon="copy"
                                            @click.prevent />
                                    </button>
                                    <action-button
                                        v-if="generatedCode"
                                        class="mt-1"
                                        width="13"
                                        height="13"
                                        icon="refresh"
                                        @click="generateStoreCode" />
                                </div>
                            </div>
                            <div v-if="storeCodeComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!storeCodeComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-google-vehicle-listing-ads-vlas#configuring-google-business-profile-store-code"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- MERCHANT CENTER -->
                    <div class="mt-4">
                        <h2>Merchant Center</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Use the guide to the right to walk through the process of setting up a Google
                                    Merchant Center account and enter its ID once you're finished
                                </p>
                            </div>
                            <div
                                v-if="!catalogComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="merchantCenterId"
                                    label="MERCHANT CENTER ID (OPTIONAL)"
                                    placeholder="Enter Merchant Center ID..."
                                    class="styled-field" />
                                <div class="small-desc">
                                    Entering the Merchant Center ID now is optional but will make it easier
                                    to activate future Google features if you have it now
                                </div>
                            </div>
                            <div v-if="catalogComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-google-vehicle-listing-ads-vlas#create-google-merchant-center-account"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="url-field">
                        <template v-if="!showDefaultFeed && !storeCodeComplete">
                            <p class="desc mb-2">
                                Once you had a Store Code, click the button below to generate a feed URL
                            </p>
                            <styled-tooltip
                                position="top"
                                nudge-top="20">
                                <template #content>
                                    <span v-if="!storeCode">Please enter a Profile Store Code</span>
                                </template>
                                <styled-button
                                    v-if="true"
                                    class="styled-button"
                                    :loading="loadingStoreCode"
                                    :disabled="!storeCode || loadingStoreCode"
                                    @click="generateFeed">
                                    Generate Feed URL
                                </styled-button>
                            </styled-tooltip>
                        </template>
                        <template v-else>
                            <p class="desc mb-2">
                                During setup you'll need the feed URL below:
                            </p>
                            <div class="field-holder">
                                <action-button
                                    v-if="urlLoading"
                                    size="35"
                                    icon=""
                                    :loading="true" />
                                <v-text-field
                                    v-else
                                    ref="urlInput"
                                    v-model="url"
                                    readonly
                                    class="styled-field" />
                                <action-button
                                    v-if="!copied"
                                    class="copy"
                                    width="27"
                                    height="27"
                                    icon="copy"
                                    @click.prevent="copy" />
                                <span
                                    v-else
                                    class="copied">Copied!</span>
                            </div>
                        </template>
                    </div>
                    <a
                        href="#"
                        class="trouble"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket')">Having trouble? Connect with support</a>
                </div>
            </div>
            <div class="button-wrapper">
                <styled-button
                    v-if="submitInProgress"
                    class="styled-button"
                    :disabled="true"
                    :loading="submitInProgress"
                    @click.prevent="submit">
                    WAIT...
                </styled-button>
                <styled-button
                    v-else
                    class="styled-button"
                    :disabled="!storeCodeComplete"
                    @click.prevent="submit">
                    CONTINUE
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import validationRules from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import { PLATFORM_ID_GOOGLE } from '@/helpers/globals';
import colors from '@/helpers/colors.js';

    export default {
        components: {
            ActionButton,
            StyledButton,
            StyledTooltip,
            Loader,
            StatusIcon
        },
        data() {
            return {
                colors,
                requiredRules: validationRules.requiredRules,
                formValid: false,
                accountId: '',
                merchantCenterId: '',
                storeCode: '',
                generatedCode: '',
                url: '',
                urlLoading: true,
                copied: false,
                loading: false,
                errorMessage: '',
                submitInProgress: false,
                loadingStoreCode: false,
                showDefaultFeed: false,
            };
        },
        computed: {
            ...mapState({
                onboardingProducts: (state) => state.onboarding.products,
                dealer: (state) => state.dealer.currentDealer,
            }),
            ...mapGetters([
                'dealerFeaturesComplete',
                'requiredFeatures'
            ]),
            hasAdAccountSetup() {
                return this.requiredFeatures.includes('google_ad_account');
            },
            adAccountComplete() {
                return this.dealerFeaturesComplete.includes('google_ad_account');
            },
            hasCatalogSetup() {
                return this.requiredFeatures.includes('google_catalog');
            },
            catalogComplete() {
                return this.dealerFeaturesComplete.includes('google_catalog');
            },
            hasStoreCodeSetup() {
                return this.requiredFeatures.includes('google_store_code');
            },
            storeCodeComplete() {
                return this.dealerFeaturesComplete.includes('google_store_code');
            },
            allAssetsSetup() {
                return this.hasStoreCodeSetup ? this.storeCodeComplete : true;
            },
            valid() {
                return this.storeCode;
            },
            existingAdAccount() {
                return this.dealer.ad_accounts.data.find(a => a.platform_id == PLATFORM_ID_GOOGLE) ?? null;
            },
            existingCatalog() {
                return this.dealer.catalogs.data.find(c => c.platform_id == PLATFORM_ID_GOOGLE) ?? null;
            },
            existingStoreCode() {
                return this.dealer.identities.data.find(i => i.platform_id == PLATFORM_ID_GOOGLE)?.configuration?.store_code ?? null;
            },
            identityId() {
                return this.dealer.identities.data.find(i => i.platform_id == PLATFORM_ID_GOOGLE)?.id ?? null;
            },
        },
        async mounted() {
            await this.initAll();
        },
        methods: {
            async generateFeed() {
                this.loadingStoreCode = true;
                await this.createNewStoreCode();
                await this.$store.dispatch('updateOnboardingStatus', {
                    features: [
                        'google_store_code'
                    ]
                });
                await this.initGeneratingRemoteFiles();
                this.loadingStoreCode = false;
                this.showDefaultFeed = true;
                await this.getURL();
            },
            async initAll() {
                this.loading = true;
                this.accountId = this.existingAdAccount?.expternal_ad_account_id ?? '';
                this.merchantCenterId = this.existingCatalog?.external_ad_account_id ?? '';
                this.storeCode = this.existingStoreCode ?? '';
                this.loading = false;

                if (this.storeCodeComplete) {
                    await this.getURL();
                }
            },
            async initGeneratingRemoteFiles() {
                const fileId = {
                    export_format_ids: []
                };
                if (this.onboardingProducts[0].platform_id == PLATFORM_ID_GOOGLE) {
                    fileId.export_format_ids = [17]; // google_auto_csv
                }
                try {
                    await this.$http.post(`/dealers/${this.dealer.id}/upload-exports`, fileId);
                } catch(error) {
                    console.log(error);
                }
            },
            async getURL() {
                try {
                    this.urlLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/get-remote-files`);
                    const remoteFiles = response.data;
                    remoteFiles.forEach(file =>{
                        if (file.remote_file_type.name.includes('Google')) {
                            this.url = file.url;
                        }
                    });
                } catch(error) {
                    console.log(error);
                } finally {
                    if (this.url) {
                        this.urlLoading = false;
                    } else {
                        setTimeout(() => {
                            this.getURL();
                        }, 5000);
                    }
                }
            },
            async createNewAdAccount() {
                const adAccountData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_GOOGLE,
                    external_ad_account_id: this.accountId,
                };
                try {
                    await this.$http.post('/ad_accounts', adAccountData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewCatalog() {
                const catalogData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_GOOGLE,
                    external_catalog_id: this.merchantCenterId,
                };
                try {
                    await this.$http.post('/catalogs', catalogData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewStoreCode() {
                const codeData = {
                    configuration: {
                        store_code: this.storeCode,
                    }
                };
                try {
                    await this.$http.put(`/identities/${this.identityId}`, codeData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async sendAllData() {
                // Account ID and Merchant Center ID are optional
                // Send them only if available
                if (!this.adAccountComplete && this.accountId) {
                    await this.createNewAdAccount();
                }

                if (!this.catalogComplete  && this.merchantCenterId) {
                    await this.createNewCatalog();
                }

                await this.checkFeatureStatus();
            },
            checkFeatureStatus() {
                return this.$store.dispatch('updateOnboardingStatus', {
                    features: [
                        'google_ad_account',
                        'google_catalog',
                        'google_store_code',
                    ]
                });
            },
            generateStoreCode() {
                this.generatedCode = Math.random().toString(36).substring(2, 12);
            },
            onCopySuccess() {
                this.$flash('Code copied to clipboard!', 'green');
            },
            async submit() {
                this.$refs.form.validate();
                if (!this.formValid) {
                    console.log('Please provide all required data');
                    return;
                }

                this.submitInProgress = true;
                this.errorMessage = '';
                await this.sendAllData();

                if (this.allAssetsSetup) {
                    this.$store.dispatch('goToNextStep');
                    this.submitInProgress = false;
                    return;
                }

                if (this.errorMessage) {
                    this.submitInProgress = false;
                }
            },
            copy() {
                const copyText = this.$refs.urlInput;
                navigator.clipboard.writeText(copyText.value).then(() => {
                    this.copied = true;
                }).catch(err => {
                    console.log('Something went wrong with copying:', err);
                });
            }
        }
    };
</script>

<style lang="scss">
#build-details-form{
    .top{
        display: flex;
        justify-content: space-between;
        &__right{
            padding: 30px;
            min-width: 130px;
        }
    }
    .desc{
        font-size:14px;
    }
    .build-details{
        padding: 30px;
        margin-top: 15px;
        border-top: 1px solid $gray-light;
    }
    .dark-word{
        color:$gray-dark;
        font-size: 10px;
        line-height: 14px;
    }
    .icon{
        margin-left: 30px;
    }
    .video-icon{
        path{
            transform: translateY(-2px);
        }
    }
    .block{
        //justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        &__icons{
            white-space: nowrap;
            width: 20%;
        }
        &__info{
            width: 100%;
            margin-bottom: 50px;
            @media (min-width: $bp-lg) {
                width: 40%;
                margin-right: 5%;
                margin-bottom: 0;
            }
        }
        &__select{
            width: 30%;
            margin-right: 5%;
            //width: 220px;
            //margin-left: 60px;
            .v-select__selections{
                min-height: 40px;
                //width: 220px;
                overflow: hidden;
            }
        }
    }
    .button-wrapper{
        text-align: right;
        padding: 0 30px;
    }
    .styled-button{
        font-size: 14px;
    }
    .trouble{
        margin-top:40px;
        display: inline-block;
        font-size: 14px;
    }
    .url-field{
        margin-top:30px;
        max-width: 400px;
        input{
            padding-top:14px;
            padding-bottom:14px;
        }
    }
    .field-holder{
        display: flex;
    }
    .copy{
        margin-left: 15px;
        transform: translateY(2px);
    }
    .copied{
        color: green;
        margin-left: 10px;
        font-size: 14px;
        margin-top:10px;
    }
    .err-message{
        padding: 0 30px;
        margin-bottom: 30px;
        text-align: center;
        color: red;
    }
    .sp{
        padding-top:2px;
    }
}
.generate-code {
    font-size: 12px;
}
.small-desc {
    font-size: 9px;
    margin: 3px;
    color: $gray;
}
</style>